import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { ReducerData, ReducerGraphic, Reducers } from '../types/reducers'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Radio } from '../types/data'
import { setCurrentRadio, setIsBuffering, setIsPlaying, setIsVideoShow } from '../store/actions/graphic'

const settings = {
    slidesToShow: 10,
    slidesToScroll: 10,
    arrows: true,
    swipe: true,
    accessibility: true,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1550,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 7
            }
        },
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
}

const SliderRadios: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()

    const HandleRadioSelect = (radio: Radio) => {
        dispatch(setIsBuffering(true))
        dispatch(setIsPlaying(false))
        dispatch(setIsVideoShow(false))
        dispatch(setCurrentRadio(radio))
        dispatch(setIsPlaying(true))
    }

    const renderRadioList = () => {
        return (
            <Slider {...settings}>
                {data.radios.map((radio) => {
                    return (
                        <div className="radioContainer" key={radio.id.toString()}>
                            <img
                                style={{ border: graphic.currentRadio?.id == radio.id ? '4px inset #dd2475' : 0 }}
                                src={radio.logo !== null ? radio.logo : ''}
                                className="radioLogoSlide"
                                onClick={() => HandleRadioSelect(radio)}
                            />
                        </div>
                    )
                })}
            </Slider>
        )
    }

    const renderSearchRadioList = () => {
        if (graphic.currentSearchResult.length > 0) {
            return (
                <Slider {...settings}>
                    {graphic.currentSearchResult.map((radio) => {
                        return (
                            <div className="radioContainer" key={radio.id.toString()}>
                                <img
                                    style={{ border: graphic.currentRadio?.id == radio.id ? '4px inset #dd2475' : 0 }}
                                    src={radio.logo !== null ? radio.logo : ''}
                                    className="radioLogoSlide"
                                    onClick={() => HandleRadioSelect(radio)}
                                />
                            </div>
                        )
                    })}
                </Slider>
            )
        } else {
            return <p>Non ho trovato nessuna radio.</p>
        }
    }

    return <div className="sliderRadios">{graphic.isSearching ? renderSearchRadioList() : renderRadioList()}</div>
}

export default SliderRadios
