import React, { useEffect } from 'react'
import './App.css'
import './IconFont.css'
import { BrowserRouter, Route } from 'react-router-dom'
import Radios from './pages/Radios'
import { useDispatch } from 'react-redux'
import { getRadios } from './store/actions/thunk_actions'
import { BrowserView, MobileView } from 'react-device-detect'
import Banner from './pages/Banner'

const App: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getRadios())
    })

    return (
        <BrowserRouter>
            <BrowserView>
                <Route exact path="/" component={Radios} />
            </BrowserView>
            <MobileView>
                <Route exact path="/" component={Banner} />
            </MobileView>
        </BrowserRouter>
    )
}

export default App
