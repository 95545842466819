import React, { useEffect, useRef, useState } from 'react'
import { findDOMNode } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import CollapseIcon from '../resources/images/icons/collapse.svg'
import PlayIcon from '../resources/images/icons/play.svg'
import PauseIcon from '../resources/images/icons/pause.svg'
import FullscreenIcon from '../resources/images/icons/fullscreen.svg'
import { setIsPlaying, setIsVideoShow } from '../store/actions/graphic'
import { ReducerGraphic, Reducers } from '../types/reducers'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

const OverlayVideo: React.FC = () => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()
    const playerVideo = useRef(null)
    const [urlVideo, setUrlVideo] = useState('')
    const indexVideo = graphic.currentRadio?.streams.find((stream) => stream.is_video == true)

    useEffect(() => {
        if (graphic.isVideoShow) {
            dispatch(setIsPlaying(true))
            //@ts-ignore
            setUrlVideo(indexVideo?.url)
        }
    }, [graphic.isVideoShow])

    const HandleCollapseVideo = () => {
        if (graphic.isVideoShow) {
            dispatch(setIsVideoShow(false))
            dispatch(setIsPlaying(false))
        }
    }

    const HandlePlay = () => {
        if (graphic.currentRadio !== null) {
            //@ts-ignore
            setUrlVideo(indexVideo?.url)
            dispatch(setIsPlaying(!graphic.isPlaying))
        }
    }

    return (
        <div
            className="overlayVideo"
            style={{
                backgroundImage: !graphic.isPlaying ? "url('" + graphic.currentRadio?.logo + "')" : 'none',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}
        >
            <img
                src={CollapseIcon}
                className="collapseVideo"
                onClick={() => {
                    HandleCollapseVideo()
                }}
            />
            <ReactPlayer
                className="playerVideo"
                ref={playerVideo}
                url={urlVideo}
                playing={graphic.isPlaying}
                controls={true}
                onPause={() => {
                    dispatch(setIsPlaying(false))
                    setUrlVideo('')
                }}
            />
            {!graphic.isPlaying && (
                <div
                    className="overlayPlay"
                    onClick={() => {
                        HandlePlay()
                    }}
                >
                    <img src={PlayIcon} className="playControl" />
                </div>
            )}
        </div>
    )
}

export default OverlayVideo
