import { CurrentMeta } from './../../types/graphic';
import { Tabs } from './../../utilities/enum/Tabs';
import { ReduxAction } from './../../types/reducers';
import { Radio } from '../../types/data';

export enum GRAPHIC_ACTIONS {
    SET_CURRENT_RADIO = 'graphicActions/setCurrentRadio',
    SET_IS_PLAYING = 'graphicActions/setIsPlaying',
    SET_IS_VIDEO_SHOW = 'graphicActions/setIsVideoShow',
    SET_IS_LIST_SHOW = 'graphicActions/setIsListShow',
    SET_IS_SEARCHING = 'graphicActions/setIsSearching',
    SET_IS_BUFFERING = 'graphicActions/setIsBuffering',
    SET_META = "graphicActions/setMeta",
    SET_CURRENT_SEARCH_RESULT = "graphicActions/setCurrentSearchResult"
}

export const setCurrentRadio: ReduxAction<Radio> = (radio: Radio) => {
    return {
        type: GRAPHIC_ACTIONS.SET_CURRENT_RADIO,
        payload: radio
    }
}

export const setIsPlaying: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_PLAYING,
        payload: status
    }
}

export const setIsVideoShow: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_VIDEO_SHOW,
        payload: status
    }
}

export const setIsListShow: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_LIST_SHOW,
        payload: status
    }
}

export const setIsSearching: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_SEARCHING,
        payload: status
    }
}

export const setIsBuffering: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_BUFFERING,
        payload: status
    }
}

export const setMeta: ReduxAction<CurrentMeta> = (meta: CurrentMeta) => {
    return {
        type: GRAPHIC_ACTIONS.SET_META,
        payload: meta
    }
}

export const setCurrentSearchResult: ReduxAction<Radio[]> = (result: Radio[]) => {
    return {
        type: GRAPHIC_ACTIONS.SET_CURRENT_SEARCH_RESULT,
        payload: result
    }
}




