import { DATA_ACTIONS } from './../actions/data';
import { ReducerData, ReduxActionData } from "../../types/reducers"

const defaultValue:ReducerData = {
    radios: [],
}

const dataState: (state: ReducerData, actions: ReduxActionData<any>) => ReducerData = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case DATA_ACTIONS.SET_RADIOS:
            return {
                ...state,
                radios: action.payload
            }
        default:
            return state
    }
}

export default dataState