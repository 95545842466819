import React from 'react'
import * as rdd from 'react-device-detect';

const Banner:React.FC = () => {

    const HandleOpenApp = () => {
        if(rdd.isIOS) {
            window.open("https://itunes.apple.com/it/app/radio-fm-world-le-migliori-radio-italiane/id593925163?mt=8", "_blank")
        } else {
            window.open("https://play.google.com/store/apps/details?id=com.hbg22.fmworldapp&hl=it", "_blank")
        }
    }

    return(
        <div className="banner" onClick={() => HandleOpenApp()}>
            <h2>Ascolta ora le tue radio preferite ovunque!</h2>
        </div>
    )
}

export default Banner