import { Radio } from '../../types/data';
import { ReduxAction } from './../../types/reducers';

export enum DATA_ACTIONS {
    SET_RADIOS = 'dataActions/setRadios',
    SET_VISUAL = "dataActions/setVisual"
}

export const setRadios: ReduxAction<Radio[]> = (radios: Radio[]) => {
    return {
        type: DATA_ACTIONS.SET_RADIOS,
        payload: radios
    }
}


