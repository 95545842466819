// export const JIGSAW_API = "https://api2.fm-world.com/22SDK/Api/api_fm_world/dev/v0.1/apps/mobile/d0b5fcf9c329e5b6af7a2fb094e0da1e4c2a4739"

// export const VISUAL_API = "https://api2.fm-world.com/22SDK/Api/api_fm_world/dev/v0.1/tvs/d0b5fcf9c329e5b6af7a2fb094e0da1e4c2a4739"

// export const META_API = "https://solutions.api-services.cloud/fmworld/fmworld/onair"

// export const SEARCH_RADIO = "https://api2.fm-world.com/22SDK/Api/api_fm_world/dev/v0.1/apps/mobile/query/"

export const RADIO_LIST = "https://connect.fm-world.com/dev-client/bundle/2/radios"

export const NOW_LIVE = "https://connect.fm-world.com/dev-client/bundle/2/live"