import { ReduxActionData } from './../../types/reducers';
import { ReducerUser } from "../../types/reducers"
import { USER_ACTIONS } from '../actions/user';

const defaultValue:ReducerUser = {
}

const userState: (state: ReducerUser, actions: ReduxActionData<any>) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        
        default:
            return state
    }
}

export default userState