import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, ReducerGraphic, Reducers } from '../types/reducers'
import PlayIcon from '../resources/images/icons/play.svg'
import PauseIcon from '../resources/images/icons/pause.svg'
import PrevIcon from '../resources/images/icons/skip-backward.svg'
import NextIcon from '../resources/images/icons/skip-forward.svg'
import VideoIcon from '../resources/images/icons/visual.svg'
import ListShowIcon from '../resources/images/icons/MostraLista.svg'
import ListHideIcon from '../resources/images/icons/NascondiLista.svg'
import SearchRadioIcon from '../resources/images/icons/SearchRadio.svg'
import CloseIcon from '../resources/images/icons/close.svg'
import DefaultLogo from '../resources/images/defaultLogo.svg'
import { setCurrentRadio, setCurrentSearchResult, setIsBuffering, setIsListShow, setIsPlaying, setIsSearching, setIsVideoShow } from '../store/actions/graphic'
import ReactPlayer from 'react-player'
import { getSearchResult } from '../store/actions/thunk_actions'
import Loader from './loader/Loader'

const PlayerBar: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()
    const playerAudio = useRef(null)
    const [urlAudio, setUrlAudio] = useState('')
    const indexAudio = graphic.currentRadio?.streams.find((stream) => stream.is_video == false)

    const HandlePlay = () => {
        if (graphic.currentRadio !== null) {
            if (graphic.isVideoShow) {
                dispatch(setIsVideoShow(false))
            }
            dispatch(setIsPlaying(!graphic.isPlaying))
        }
    }

    const HandlePrev = () => {
        if (graphic.currentRadio !== null) {
            const index = data.radios.findIndex((radio) => radio.id == graphic.currentRadio?.id)
            if (index > 0) {
                dispatch(setIsPlaying(false))
                dispatch(setCurrentRadio(data.radios[index - 1]))
                dispatch(setIsPlaying(true))
            } else {
                dispatch(setIsPlaying(false))
                dispatch(setCurrentRadio(data.radios[data.radios.length - 1]))
                dispatch(setIsPlaying(true))
            }
        }
    }

    const HandleNext = () => {
        if (graphic.currentRadio !== null) {
            const index = data.radios.findIndex((radio) => radio.id == graphic.currentRadio?.id)
            if (index < data.radios.length - 1) {
                dispatch(setIsPlaying(false))
                dispatch(setCurrentRadio(data.radios[index + 1]))
                dispatch(setIsPlaying(true))
            } else {
                dispatch(setIsPlaying(false))
                dispatch(setCurrentRadio(data.radios[0]))
                dispatch(setIsPlaying(true))
            }
        }
    }

    const handleShowVideo = () => {
        if (graphic.currentRadio?.streams.find((stream) => stream.is_video == true) !== undefined) {
            dispatch(setIsVideoShow(true))
        }
    }

    const handleShowList = () => {
        if (graphic.isSearching) {
            dispatch(setIsSearching(false))
        }
        dispatch(setIsListShow(!graphic.isListShow))
    }

    const renderCover = () => {
        if (graphic.meta !== null) {
            if (graphic.meta.track_image !== null) {
                return graphic.meta.track_image
            }
            if (graphic.meta.show_image !== null) {
                return graphic.meta.show_image
            }
        }
        if (graphic.currentRadio !== null && graphic.currentRadio.logo !== null) {
            return graphic.currentRadio.logo
        }
        return DefaultLogo
    }

    const renderTitle = () => {
        if (graphic.meta !== null) {
            if (graphic.meta?.track_title !== null) {
                if (graphic.meta?.track_artist !== null) {
                    return graphic.meta?.track_title + ' - ' + graphic.meta?.track_artist
                } else {
                    return graphic.meta?.track_title
                }
            }
            if (graphic.meta?.show_title !== null) {
                if (graphic.meta?.show_speakers !== null) {
                    return graphic.meta?.show_title + ' con ' + graphic.meta?.show_speakers
                } else {
                    return graphic.meta?.show_title
                }
            }
        }
        if (graphic.currentRadio !== null) {
            return graphic.currentRadio.slogan
        }
        return ''
    }

    const renderRadioName = () => {
        if (graphic.currentRadio !== null) {
            return graphic.currentRadio.name
        }
        return ''
    }

    const HandleSearchIconClick = () => {
        if (graphic.isSearching) {
            dispatch(setIsSearching(false))
            dispatch(setIsListShow(false))
        } else {
            dispatch(setIsSearching(true))
            dispatch(setIsListShow(false))
        }
    }

    const HandleSearch = (string: string) => {
        if (string.length >= 3) {
            dispatch(setIsListShow(true))
            dispatch(getSearchResult(string))
        } else {
            dispatch(setCurrentSearchResult([]))
            dispatch(setIsListShow(false))
        }
    }

    useEffect(() => {
        if (graphic.currentRadio?.streams.find((stream) => stream.is_video == true) == undefined && graphic.isVideoShow) {
            dispatch(setIsPlaying(false))
            dispatch(setIsVideoShow(false))
        }
        if (indexAudio) {
            //@ts-ignore
            setUrlAudio(indexAudio?.url)
        }
    }, [graphic.currentRadio])

    useEffect(() => {
        if(graphic.isBuffering) {
            return
        }
        if (graphic.isPlaying) {
            //@ts-ignore
            setUrlAudio(indexAudio?.url)
        } else {
            setUrlAudio('')
        }
    }, [graphic.isPlaying])

    return (
        <div className="playerBar" style={{ marginTop: graphic.isVideoShow ? 220 : 0 }}>
            <img src={renderCover()} className="currentRadioLogo" />
            {!graphic.isVideoShow && graphic.isPlaying ? (
                <ReactPlayer
                    ref={playerAudio}
                    className="playerAudio"
                    onBufferEnd={() => {
                        dispatch(setIsBuffering(false))
                    }}
                    url={urlAudio}
                    playing={graphic.isPlaying}
                />
            ) : null}
            <div className="interactions">
                <div className="controls">
                    <img
                        src={PrevIcon}
                        className="prevIcon"
                        onClick={() => {
                            HandlePrev()
                        }}
                    />
                    <div
                        className="playContainer"
                        onClick={() => {
                            HandlePlay()
                        }}
                    >
                        {graphic.isBuffering ? (
                            <Loader />
                        ) : (
                            <img src={!graphic.isVideoShow && graphic.isPlaying ? PauseIcon : PlayIcon} className="playIcon" />
                        )}
                        
                    </div>
                    <img
                        src={NextIcon}
                        className="nextIcon"
                        onClick={() => {
                            HandleNext()
                        }}
                    />
                </div>
                <div className="meta">
                    {graphic.isSearching ? (
                        <input
                            type="text"
                            autoFocus
                            className="searchField"
                            placeholder="Inserisci nome radio"
                            onChange={(i) => {
                                HandleSearch(i.target.value)
                            }}
                        />
                    ) : (
                        <>
                            <p className="placeholder">NOW LIVE</p>
                            <p className="title">{renderTitle()}</p>
                            <p className="radioName">{renderRadioName()}</p>
                        </>
                    )}
                </div>
                <div className="otherControls">
                    <img
                        src={graphic.isSearching ? CloseIcon : SearchRadioIcon}
                        className="searchIcon"
                        onClick={() => {
                            HandleSearchIconClick()
                        }}
                    />
                    {graphic.currentRadio?.streams.find((stream) => stream.is_video == true) !== undefined && !graphic.isVideoShow && (
                        <img
                            src={VideoIcon}
                            className="videoIcon"
                            onClick={() => {
                                handleShowVideo()
                            }}
                        />
                    )}
                    <img
                        src={graphic.isListShow && !graphic.isSearching ? ListHideIcon : ListShowIcon}
                        className="listIcon"
                        onClick={() => {
                            handleShowList()
                        }}
                    />
                </div>
                <div className="metaMobile">
                    <p className="placeholder">NOW LIVE</p>
                    <p className="title">{renderTitle()}</p>
                    <p className="radioName">{renderRadioName()}</p>
                </div>
            </div>
        </div>
    )
}

export default PlayerBar
