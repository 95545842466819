import { setCurrentRadio, setMeta, setCurrentSearchResult } from './graphic';
import { setRadios } from './data';
import { RADIO_LIST, NOW_LIVE } from './../../resources/constants';
import axios from "axios"
import { Radio } from '../../types/data';

const headers = {headers: {'Authorization': 'sys_admin'}}

export const getRadios:any = (token:string) => async(dispatch: (any:any) => void) => {
    const filter = {
        filter : {
            videoOnly : false,
            returnEmptyStreams : false,
            returnOfflineStreams : false
        },
        bounds : {
            offset : 0,
            items : 300
        }
    }
    const radios = await axios.post(RADIO_LIST, filter, headers).then((response) => response.data.result)
    dispatch(setRadios(radios))
    dispatch(setCurrentRadio(radios[0]))
}

export const getMeta:any = (radio:Radio) => async(dispatch: (any:any) => void) => {
    const filter = {
        filter: {
            id_radio: radio.id
        }
    }
    //console.log(radio)
    const meta = await axios.post(NOW_LIVE, filter, headers).then((response) => response.data.result)
    //console.log(meta)
    if(meta.length > 0) {
        dispatch(setMeta(meta[0].live))
        return
    } else {
        //@ts-ignore
        dispatch(setMeta(null))
        return
    }
}

export const getSearchResult = (string:string) => async(dispatch: (any:any) => void) => {
    const filter = {
        filter : {
            query: string,
            videoOnly : false,
            returnEmptyStreams : false,
            returnOfflineStreams : false
        },
        bounds : {
            offset : 0,
            items : 300
        }
    }
    const radios = await axios.post(RADIO_LIST, filter, headers).then((response) => response.data.result)
    dispatch(setCurrentSearchResult(radios))
    //console.log(radios)
}