import { GRAPHIC_ACTIONS } from './../actions/graphic';
import { ReduxActionData, ReducerGraphic } from "../../types/reducers"
import { Tabs } from '../../utilities/enum/Tabs';

const defaultValue:ReducerGraphic = {
    currentRadio: null,
    isPlaying: false,
    isVideoShow: false,
    isListShow: false,
    isSearching: false,
    isBuffering: false,
    meta: null,
    currentSearchResult: []
}

const graphicState: (state: ReducerGraphic, actions: ReduxActionData<any>) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case GRAPHIC_ACTIONS.SET_CURRENT_RADIO:
            return {
                ...state,
                currentRadio: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_PLAYING:
            return {
                ...state,
                isPlaying: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_VIDEO_SHOW:
            return {
                ...state,
                isVideoShow: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_LIST_SHOW:
            return {
                ...state,
                isListShow: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_SEARCHING:
            return {
                ...state,
                isSearching: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_BUFFERING:
            return {
                ...state,
                isBuffering: action.payload
            }
        case GRAPHIC_ACTIONS.SET_META:
            return {
                ...state,
                meta: action.payload
            }
        case GRAPHIC_ACTIONS.SET_CURRENT_SEARCH_RESULT:
            return {
                ...state,
                currentSearchResult: action.payload
            }
        default:
            return state
    }
}

export default graphicState