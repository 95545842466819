import React from 'react'
import './Loader.css'

const Loader:React.FC = () => {
    return(
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader