import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OverlayVideo from '../components/OverlayVideo'
import PlayerBar from '../components/PlayerBar'
import { ReducerGraphic, Reducers } from '../types/reducers'
import SliderRadios from '../components/SliderRadios'
import { getMeta } from '../store/actions/thunk_actions'
import { setMeta } from '../store/actions/graphic'
import ts from 'typescript'
//@ts-ignore
var timerMeta

const Radios: React.FC = () => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()

    useEffect(() => {
        if (graphic.isPlaying) {
            //@ts-ignore
            if(timerMeta !== undefined) {
                //@ts-ignore
                dispatch(setMeta(null))
                //@ts-ignore
                clearInterval(timerMeta)
            }
            dispatch(getMeta(graphic.currentRadio))
            timerMeta = setInterval(() => {
                dispatch(getMeta(graphic.currentRadio))
            }, 10000)
        } else {
            //@ts-ignore
            dispatch(setMeta(null))
            //@ts-ignore
            clearInterval(timerMeta)
        }
    }, [graphic.isPlaying, graphic.currentRadio])

    return (
        <div>
            {graphic.isVideoShow && <OverlayVideo />}
            <PlayerBar />
            {graphic.isListShow && <SliderRadios />}
        </div>
    )
}

export default Radios
